import React from 'react'
import styled from 'styled-components'
import SolutionSmallEnterpriseData from '../Data/SolutionSmallEnterpriseData.json'
// import SolutionSmallEnterpriseTableData from '../Data/SolutionSmallEnterpriseTableData.json'
// import { SmallTable } from './SmallTable'
import { Block } from './Block'
import { Button } from './Button'
import { LinkHubspot } from './LinkHubspot'
import { AppSmallTable } from './AppSmallTable'

const AppSmallBannerWpper = styled.div`
  max-width: 1920px;
  margin: auto;
  .SmallContain {
    max-width: 1920px;
    padding-right: 5rem;
    margin: auto;
    @media (max-width: 1090px) {
      padding-right: unset;
    }
  }
  .SmallSection {
    display: flex;
    justify-content: space-around;
    @media (max-width: 1090px) {
      flex-flow: wrap;
    }
  }
  .SmallHeadingSmall {
    display: none;
    @media (max-width: 1090px) {
      text-align: center;
      display: block;
      padding-bottom: 3%;
    }
  }
  .SmallHeadingBig {
    padding: 0px 0px 3% 45px;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .SmallImage {
    height: auto;
    order: 1;
    @media (max-width: 1920px) and (min-width: 1830px) {
      margin-top: -3rem;
    }
    @media (max-width: 1090px) {
      order: 0;
      margin-top: auto;
    }
  }
  .SmallImageCenter {
    width: 75%;
    display: flex;
    float: right;
    @media (max-width: 1090px) {
      width: 100%;
      float: unset;
    }
  }
  .SmallParaSection {
    order: 0;
    margin-top: 3rem;
    padding-left: 5rem;
    @media (max-width: 1920px) and (min-width: 1820px) {
      margin-top: 4%;
    }
    @media (max-width: 1090px) {
      order: 1;
      padding-left: unset;
    }
  }
  .SmallParaPonitSection {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
  .SmallPara {
    max-width: 825px;
    padding: 0px 0px 15px 45px;
    @media (max-width: 1090px) {
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }
  .SmallDownPara {
    max-width: 825px;
    color: #047cc2;
    padding: 6rem 0px 0px 45px;
    @media (max-width: 1090px) {
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }
  .SmallParaOne {
    max-width: 825px;
    padding: 15px 0px 15px 45px;
    color: #047cc2;
    font-size: 1.5rem;
    font-weight: 600;
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
    }
  }
  .SmallParaButton {
    max-width: auto;
    padding: 15px 100px 15px 70px;
    @media (max-width: 1090px) {
      max-width: 825px;
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }
  .SmallParaButtonOne {
    max-width: auto;
    padding: 25px 100px 15px 70px;
    @media (max-width: 1090px) {
      max-width: 825px;
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }

  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .PonitPara {
    margin-top: 0%;
  }
  .buttonStyle {
    background: linear-gradient(90deg, #16b4ff, #644eff, #16b4ff);
    background-size: 400%;
    border-radius: 30px;
    border: none;
    padding: 0 20px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    /* margin: 3% 0% 3% 16%; */
    color: #fff;
    margin: 5% 0% 3% 0%;
    color: #fff;
    display: flex;
    margin: 2rem 0rem 5rem 17rem;
    @media (max-width: 1090px) {
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }
    @keyframes animate {
      0% {
        background-position: 0%;
      }
      100% {
        background-position: 400%;
      }
    }
  }
  .buttonStyle:hover {
    cursor: pointer;
    animation: animate 8s linear infinite;
    box-shadow: 0 8px 16px 0 (90deg, #16b4ff, #644eff, #16b4ff);

    .buttonStyle::before {
      filter: blur(20px);
      opacity: 1;
      animation: animate 8s linear;
    }
  }
  .buttonStyle::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    background: linear-gradient(90deg, #16b4ff, #644eff, #16b4ff);
    background-size: 400%;
    border-radius: 40px;
    opacity: 0;
    transition: 0.5s;
  }

  @keyframes animate {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 400%;
    }
  }
  .SmallDisplay {
    display: none;
  }
`

export const AppSmallBanner = () => {
  return (
    <AppSmallBannerWpper>
      {SolutionSmallEnterpriseData.map((data, index) => {
        return (
          <div key={index}>
            <Block
              Heading={data.Title}
              paraOne={data.ParaOne}
              paraTwo={data.ParaTwo}
              paraThree={data.paraThree}
              ImagePath={data.ImagePath}
              mainContain="SmallContain"
              containHeading="SmallHeadingSmall"
              containHeadingBig="SmallHeadingBig"
              containParaOne="SmallParaOne"
              containParaTwo="SmallPara"
              containParaFour="SmallDisplay"
              containParaFive="SmallDisplay"
              containParaThree="SmallDownPara"
              InternalSection="SmallSection"
              containImage="SmallImage"
              altName={data.AltName}
              paraSection="SmallParaSection"
              allImage="SmallImageCenter"
            />
          </div>
        )
      })}
      {/* <div>
        {SolutionSmallEnterpriseTableData.map((item, index) => {
          return (
            <div key={index}>
              <SmallTable
                Challenge={item.challengesPointOne}
                AddressPonit={item.challengesPointTwo}
                HeadingOne={item.HeadingOne}
                HeadingOneDen={item.HeadingOneDen}
                paraOne={item.paraOne}
                HeadingTwo={item.HeadingTwo}
                HeadingTwoDen={item.HeadingTwoDen}
                paraTwo={item.paraTwo}
                HeadingThree={item.HeadingThree}
                HeadingThreeDen={item.HeadingThreeDen}
                paraThree={item.paraThree}
              />
            </div>
          )
        })}
      </div> */}
      <AppSmallTable />
      <div className="SmallParaPonitSection">
        <LinkHubspot to="https://share.hsforms.com/15ZhMdunrTji6Z5_wWjrDyA4702y">
          <span className="sr-only">Jump to packages</span>
          <Button
            cta="Click here to get started."
            label="Click here to get started."
            type="submit"
            className="demobutton"
          />
        </LinkHubspot>
      </div>
    </AppSmallBannerWpper>
  )
}
