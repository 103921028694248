import React, { useEffect } from 'react'
import { Layout } from '../../components/AppLayout'
import styled from 'styled-components'
import { AppAnalyticsBanner } from '../../components/AppAnalyticsBanner'
import Scroll from 'react-scroll'
import { RDHelmet } from '../../components/RDHelmet'
import { AppAnalyticsSecondSection } from '../../components/AppAnalyticsSecondSection'
// import { AppAnalyticsCards } from '../../components/AppAnalyticsCards'
// import { AppAnalyticsSideBarMenu } from '../../components/AppAnalyticsSideBarMenu'
import { AppAnalyticsHoverSideBar } from '../../components/AppAnalyticsHoverSideBar'
import { AppHomeBookDemo } from '../../components/AppHomeBookDemo'

var scroll = Scroll.animateScroll

const scrollData = {
  ByCompany: 700,
  FreeInstance: 900,
  SmallEnterprise: 1500,
  MediumEnterprise: 3000,
  LargeEnterprise: 4300,
  ByRoles: 5700,
  Executives: 5900,
  Procurement: 7400,
  Finance: 8600,
}

const scrollToIndex = (location: string) => {
  const item = Object.entries(scrollData).find((item) => item[0] == location)
  if (item && item[1]) {
    scroll.scrollTo(item[1])
  }
}

const AnalyticsWrapper = styled.div`
  body: {
    overflow-x: hidden;
    overflow-y: scroll;
  }
`

const Analytics = (props: any) => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '408-352-5162', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' 226 Airport Parkway Suite 250',
      addressRegion: ' San Jose, California 95110',
      postalCode: '95110',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }
  useEffect(() => {
    if (props.location.state && props.location.state.scroll) {
      scrollToIndex(props.location.state.scroll)
    }
  }, [])

  return (
    <Layout scrollToIndex={scrollToIndex}>
      <RDHelmet
        subtitle={'Analytics'}
        contentDescription="Empower decisions with Raindrop Analytics: uncover insights, monitor performance, and optimize spend management seamlessly."
        contentKeywords="content='Spend analytics, Data insights, Performance monitoring, Spend optimization, Procurement analytics, Business intelligence, Cost efficiency, Data visualization, Financial analytics, Strategic insights, Spend management, Analytics solutions, Reporting tools, Predictive analytics, Decision support'"
        contentOG="AI Powered Source-to-Pay SaaS Platform | Try Raindrop Free"
        contentOgDescription="Empower decisions with Raindrop Analytics: uncover insights, monitor performance, and optimize spend management seamlessly."
        contentOgUrl="https://raindrop.com/solutions/Analytics/"
        schemaMarkup={schemaMarkup}
      />
      <AnalyticsWrapper>
        <AppAnalyticsBanner />
        <AppAnalyticsSecondSection />
        <AppAnalyticsHoverSideBar />
        {/* <AppAnalyticsSideBarMenu /> */}
        {/* <AppAnalyticsCards /> */}
        <AppHomeBookDemo />
      </AnalyticsWrapper>
    </Layout>
  )
}

export default Analytics
