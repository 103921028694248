import React from 'react'
import styled from 'styled-components'
import { ReUseHOneTag } from './ReUseHOneTag'
import { ReuseImgTag } from './ReuseImgTag'
import SCOBannerData from '../Data/SCOBannerData.json'

const AppSCOBannerWpper = styled.div`
  max-width: 1920px;
  margin: auto;
  background-color: #102c64;
  .MainSCOsection {
    padding: 150px 96px 50px 96px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1080px) {
      flex-wrap: wrap;
      padding: 80px 15px 50px 15px;
    }
  }
  .SCOBannerHeading {
    /* max-width: 650px; */
    line-height: 1.30435em;
    padding-bottom: 3%;
    padding-top: 10%;
    font-size: 2.5rem;
    color: #f1f1f1;
    /* @media (max-width: 1540px) {
      max-width: 750px;
    } */
    @media (max-width: 1080px) {
      padding-top: unset;
      max-width: unset;
    }
  }
  .HeadingSection {
    @media (max-width: 1080px) {
      margin: auto;
      order: 1;
    }
  }
  .ImageSection {
    @media (max-width: 1080px) {
      margin: auto;
      order: 0;
    }
  }
  .SCOBannerimage {
    width: 80%;
    height: auto;
    @media (max-width: 1080px) {
      margin-top: 0%;
      float: unset;
      width: 100%;
      justify-content: center;
    }
  }
`

export const AppSCOBanner = () => {
  return (
    <AppSCOBannerWpper>
      {SCOBannerData.map((data, index) => {
        return (
          <div className="MainSCOsection" key={index}>
            <div className="HeadingSection">
              <ReUseHOneTag Heading={data.title} HeadingStyle="SCOBannerHeading" />
            </div>
            <div className="ImageSection">
              <ReuseImgTag ImageStyle="SCOBannerimage" ImagePath={data.ImagePath} AltName={data.AltName} />
            </div>
          </div>
        )
      })}
    </AppSCOBannerWpper>
  )
}
