import React, { useEffect } from 'react'
import { Layout } from '../components/AppLayout'
import styled from 'styled-components'
import { AppServiceBanner } from '../components/AppServiceBanner'
import { AppServiceViewCard } from '../components/AppServiceViewCard'
import { AppServiceDifferentiators } from '../components/AppServiceDifferentiators'
import { AppServiceExperience } from '../components/AppServiceExperience'
import { AppServiceAlliances } from '../components/AppServiceAlliances'
import { AppServiceEngagements } from '../components/AppServiceEngagements'
import { AppDeliveryModelServiceBanner } from '../components/AppDeliveryModelServiceBanner'
import { AppDeliveryModelServicePonit } from '../components/AppDeliveryModelServicePonit'
import { AppDeliveryModelFrontEndPonit } from '../components/AppDeliveryModelFrontEndPonit'
import { AppDeliveryModelBackEndPonit } from '../components/AppDeliveryModelBackEndPonit'
import { AppDeliveryModelOperationPonit } from '../components/AppDeliveryModelOperationPonit'
import Scroll from 'react-scroll'
import { RDHelmet } from '../components/RDHelmet'
var scroll = Scroll.animateScroll

const scrollData = {
  Differentiators: 1000,
  Services: 3000,
  DeliveryModel: 3700,
}

const scrollToIndex = (location: string) => {
  const item = Object.entries(scrollData).find((item) => item[0] == location)
  if (item && item[1]) {
    scroll.scrollTo(item[1])
  }
}

const ServicesWrapper = styled.div`
  body: {
    overflow-x: hidden;
    overflow-y: scroll;
  }
`

const Services = (props: any) => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '408-352-5162', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' 226 Airport Parkway Suite 250',
      addressRegion: ' San Jose, California 95110',
      postalCode: '95110',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }
  useEffect(() => {
    if (props.location.state && props.location.state.scroll) {
      scrollToIndex(props.location.state.scroll)
    }
  }, [])

  return (
    <ServicesWrapper>
      <RDHelmet
        subtitle={'Services'}
        contentDescription="Discover innovative procurement solutions tailored to optimize your business needs."
        contentKeywords="content='Procurement services, Spend management, Supplier management, Cost optimization, Business efficiency, Strategic sourcing, Contract management, Supply chain solutions, Procurement automation, Tailored solutions'"
        contentOG="AI Powered Source-to-Pay SaaS Platform | Try Raindrop Free"
        contentOgDescription="Discover innovative procurement solutions tailored to optimize your business needs."
        contentOgUrl="https://raindrop.com/Services"
        schemaMarkup={schemaMarkup}
      />
      <Layout scrollToIndex={scrollToIndex}>
        <AppServiceBanner />
        <AppServiceViewCard />
        <AppServiceDifferentiators />
        <AppServiceExperience />
        <AppServiceAlliances />
        <AppServiceEngagements />
        <AppDeliveryModelServiceBanner />
        <AppDeliveryModelServicePonit />
        <AppDeliveryModelFrontEndPonit />
        <AppDeliveryModelOperationPonit />
        <AppDeliveryModelBackEndPonit />
      </Layout>
    </ServicesWrapper>
  )
}

export default Services
