import React from 'react'
import styled from 'styled-components'
// import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'

const AppContractLifecycleManagementHoverSidebarWapper = styled.div`
  max-width: 1920px;
  height: 100vh;
  margin: auto;
  /* background-color: #f5f5f5; */
  scroll-behavior: smooth;
  overflow: hidden;
  .ContractManagementHeading {
    margin: 0;
    text-align: center;
    padding: 0px 0px 100px 0px;
  }
  .tab {
    float: left;
    /* border: 1px solid #ccc; */
    /* background-color: #f1f1f1; */
    width: 33vw;
    height: auto;
    padding-left: 50px;

    @media (min-width: 1920px) {
      width: 35vw;
      padding-left: 124px;
    }
    @media (max-width: 440px) {
      /* padding-left: 4px; */
      padding: 0;
    }
  }

  /* Style the buttons inside the tab */
  .tab button {
    margin-bottom: 14px;
    display: block;
    background-color: inherit;
    color: #34a1d5;
    padding: 22px 16px 22px 90px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    font-size: 24px;
    font-weight: bold;
    transition: 0.5s;
    /* box-shadow: 8px 8px 10px 0 rgb(0 0 0 / 10%); */
    /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    @media (max-width: 1090px) {
      font-size: 18px;
    }
    @media (max-width: 825px) {
      font-size: 14px;
      padding-left: 10px;
    }
    @media (max-width: 440px) {
      font-size: 12px;
      padding-left: 4px;
      margin: 0;
    }
  }

  /* Change background color of buttons on hover */
  .tab button:hover {
    color: #047cc2;
    box-shadow: 2px 4px 10px 0 rgb(0 0 0 / 10%);
    font-size: 25px;
    border-left: 5px solid #047cc2;
    @media (max-width: 440px) {
      font-size: 13px;
    }
    @media (max-width: 825px) {
      font-size: 13px;
    }
    /* @media (max-width: 1034px) {
      font-size: 19px;
    } */
  }

  /* Create an active/current "tab button" class */
  .tab button.active {
    color: #047cc2;
    box-shadow: 2px 4px 10px 0 rgb(0 0 0 / 10%);

    border-left: 5px solid #047cc2;
  }

  /* Style the tab content */
  .tabcontent {
    float: left;
    padding: 0px 0px 0px 80px;
    /* border: 1px solid #ccc; */
    width: 60vw;
    border-left: none;
    height: 100vh;
    display: block;
    @media (max-width: 440px) {
      margin: 0;
      padding: 4px;
    }
    @media (min-width: 1920px) {
      width: 43vw;
      padding-left: 124px;
    }
  }

  /* Clear floats after the tab */
  .clearfix::after {
    content: '';
    clear: both;
    display: table;
  }
  .Sidebar-imageOne {
    width: 15vw;
    height: auto;
    display: block;
    margin-left: 160px;
    margin-right: auto;
    @media (max-width: 440px) {
      margin-left: 80px;
    }
    @media (min-width: 2560px) {
      width: 9vw;
      height: auto;
    }
    @media (min-width: 1920px) {
      width: 11vw;
      height: auto;
    }
  }
  .SideBar-points {
    margin: 20px 0px 0px 0px;
    font-size: 18px;
    @media (max-width: 1090px) {
      font-size: 16px;
    }
    @media (max-width: 825px) {
      font-size: 14px;
    }
    @media (max-width: 440px) {
      font-size: 12px;
      margin: 4px;
    }
  }
  .Sidebar-icons {
    width: 20px;
    height: auto;
    padding: 0px 5px 0px 0px;
  }
  .RightSidetabcontent {
    width: 100vw;
  }
  .tablinks {
    border-radius: 50px;
  }
`

export const AppContractLifecycleManagementHoverSidebar = () => {
  function openCity(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>, cityName: string) {
    var i, tabcontent, tablinks
    tabcontent = document.getElementsByClassName('tabcontent') as HTMLCollectionOf<HTMLElement>
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none'
    }
    tablinks = document.getElementsByClassName('tablinks')
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '')
    }
    const cityElement = document.getElementById(cityName)
    if (cityElement) {
      cityElement.style.display = 'block'
    }
    evt.currentTarget.className += ' active'
  }
  return (
    <AppContractLifecycleManagementHoverSidebarWapper>
      <ReUseHTwotag
        Heading="Maximizing efficiency, compliance, and value across the entire contract lifecycle"
        HeadingStyle="ContractManagementHeading"
      />
      <div className="SideBarContainer">
        <div className="tab">
          <button className="butn tablinks" onMouseOver={(event) => openCity(event, 'ContractCreation')}>
            Contract Creation
          </button>
          <button className="tablinks" onMouseOver={(event) => openCity(event, 'SupplierCollaboration')}>
            Supplier Collaboration
          </button>
          <button className="tablinks" onMouseOver={(event) => openCity(event, 'CentralizedManagement')}>
            Centralized Management
          </button>
          <button className="tablinks" onMouseOver={(event) => openCity(event, 'ScalabilityandIntegration')}>
            Scalability and Integration
          </button>
        </div>
        <div className="RightSidetabcontent">
          <div id="ContractCreation" className="FirstSlide tabcontent">
            <img
              src="https://storage.googleapis.com/raindroppublic/website_data/Contract-Creation.png"
              className="Sidebar-imageOne"
            />
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Generative Al Contract Creation: Utilize Generative Al to create contracts from scratch based on
              predefined parameters such as contract type, scope of work, terms and conditions, and legal requirements.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Clause Modification: Allow users to modify generated contracts by adding, swapping out, or removing
              clauses, terms, and conditions to suit your specific needs.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Template Library: Maintain a library of pre-approved contract templates covering various types of
              agreements (e.g., MSA, PSA, SaaS Agreements, service level agreements, nondisclosure agreements) for quick
              deployment.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Natural Language Processing (NLP): Implement NLP capabilities to ensure contracts are written in clear,
              understandable language while also identifying potential legal issues or ambiguities.
            </p>
          </div>
          <div id="SupplierCollaboration" className="tabcontent">
            <img
              src="https://storage.googleapis.com/raindroppublic/website_data/Supplier-Collaboration.png"
              className="Sidebar-imageOne"
            />
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Real-Time Collaboration: Enable real-time collaboration between contract stakeholders and suppliers for
              negotiation, review, and redlining of contract terms and conditions.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Version Control: Track changes made during the negotiation process, maintain version history, and provide
              an audit trail to ensure transparency and accountability
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Automated Notifications: Notify relevant parties of pending actions, upcoming deadlines, or changes in
              contract status to facilitate timely responses and decision-making.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Secure Communication: Ensure data security and confidentiality during contract discussions through
              encrypted communication channels and role-based access controls.
            </p>
          </div>

          <div id="CentralizedManagement" className="tabcontent">
            <img
              src="https://storage.googleapis.com/raindroppublic/website_data/Centralized-Management.png"
              className="Sidebar-imageOne"
            />
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Unified Dashboard: Provide a centralized dashboard for managing all contracts, including their status, key
              milestones, and financial commitments.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Financial Integration: Integrate with financial systems to track contractual spend commitments, monitor
              budget utilization, and forecast future expenses.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Analytics and Reporting: Generate insights through analytics and reporting tools to identify trends, track
              supplier performance, and optimize contract terms for cost savings and risk mitigation.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Compliance Monitoring: Monitor contract compliance with regulatory requirements, internal policies, and
              industry standards to mitigate legal and financial risks.
            </p>
          </div>
          <div id="ScalabilityandIntegration" className="tabcontent">
            <img
              src="https://storage.googleapis.com/raindroppublic/website_data/Scalability-and-Integration.png"
              className="Sidebar-imageOne"
            />
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Scalable Architecture: Design the platform with scalability in mind to accommodate growing volumes of
              contracts and users without compromising performance or reliability.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Integration Capabilities: Integrate with existing enterprise systems such as ERP and other existing CLM
              and procurement platforms, to streamline data exchange and ensure seamless workflow integration.
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              API Accessibility: Offer APIs for third-party integration, allowing organizations to extend the platform's
              functionality and leverage additional services or data sources.
            </p>
          </div>
        </div>
      </div>
      <div className="clearfix"></div>
    </AppContractLifecycleManagementHoverSidebarWapper>
  )
}
