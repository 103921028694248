import React from 'react'
import styled from 'styled-components'
import { ContactsUsCard } from './ContactsUsCard'

const AppContactUsCardWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .CardManager {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 4rem;
    @media (max-width: 1450px) {
      padding-bottom: 2rem;
    }
    @media (max-width: 1280px) {
      padding-bottom: unset;
    }
    @media (max-width: 1080px) {
      flex-wrap: wrap;
    }
  }
  .SupportCard {
    /* height: auto; */
    padding-top: 2rem;
    @media (max-width: 1080px) {
      padding-top: unset;
    }
  }
  .displayNone {
    display: none;
  }
  .ListType {
    list-style-type: none;
    text-decoration: none;
    list-style: none;
    display: flex;
    justify-content: center;
    padding-top: 1rem;
  }
  .mainController {
    height: 155vh;
    @media (max-width: 1000px) and (min-width: 425px) {
      height: 240vh;
    }
    @media (max-width: 425px) {
      height: 160vh;
    }
  }
`

export const AppContactUsCard = () => {
  return (
    <AppContactUsCardWapper>
      <div className="mainController">
        <div className="CardManager">
          {SaleData.map((data, index) => {
            return (
              <div key={index}>
                <ContactsUsCard
                  CardImage={data.imagePath}
                  CardHeading={data.heading}
                  CardPara={data.Cardpara}
                  CardPhone={data.NumberPara}
                  CardEmail={data.EmailPara}
                  CardAlt={data.AltName}
                  mailTo={data.MailTo}
                  ListType="displayNone"
                  iconimg="ListType"
                />
              </div>
            )
          })}
          {DemoData.map((data, index) => {
            return (
              <div key={index}>
                <ContactsUsCard
                  CardImage={data.imagePath}
                  CardHeading={data.heading}
                  CardPara={data.Cardpara}
                  CardAlt={data.AltName}
                  ButtonName={data.buttonName}
                  URL={data.ShiftUrl}
                  ListType="ListType"
                  iconimg="displayNone"
                />
              </div>
            )
          })}
        </div>
        <div className="SupportCard">
          {SupportData.map((data, index) => {
            return (
              <div key={index}>
                <ContactsUsCard
                  CardImage={data.imagePath}
                  CardHeading={data.heading}
                  CardPara={data.Cardpara}
                  CardPhone={data.NumberPara}
                  CardEmail={data.EmailPara}
                  CardAlt={data.AltName}
                  mailTo={data.MailTo}
                  ListType="displayNone"
                  iconimg="ListType"
                />
              </div>
            )
          })}
        </div>
      </div>
    </AppContactUsCardWapper>
  )
}

const DemoData = [
  {
    id: 'ScheduleADemo',
    imagePath: 'https://storage.googleapis.com/raindroppublic/website_data/ScheduleDemoImage.png',
    heading: 'Schedule a Demo',
    Cardpara: 'Want to schedule a demo with Raindrop. Get a demo here.',
    buttonName: 'Talk To Us',
    ShiftUrl: '/demo',
    AltName: 'ScheduleADemoIcon',
  },
]

const SaleData = [
  {
    id: 'TalkToSales',
    imagePath: 'https://storage.googleapis.com/raindroppublic/website_data/PhoneContactUsImage.png',
    heading: 'Talk to Sales',
    Cardpara: 'Interested in Raindrop? Just pick up the phone to chat with a member of our sales team.',
    NumberPara: '408-352-5162',
    EmailPara: 'Contact Sales',
    AltName: 'TalkToSalesIcon',
    MailTo: 'mailto:sales@raindrop.com',
  },
]

const SupportData = [
  {
    id: 'ContactCustomerSupport',
    imagePath: 'https://storage.googleapis.com/raindroppublic/website_data/messageImageContactus.png',
    heading: 'Contact Customer Support',
    Cardpara: 'Sometimes you need a little help from Raindrop’s support rep. Don’t worry… we’re here for you.',
    NumberPara: '  408-352-5162',
    EmailPara: 'Contact Support',
    MailTo: 'mailto:support@raindrop.com',
    AltName: 'ContactCustomerSupportIcon',
  },
]
