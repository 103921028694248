import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

export interface ModalProps {
  showModal: boolean
  setShowModal: (value: boolean) => void
}

const AppModalSliderWrapper = styled.div<{ opacity: number }>`
  // Your existing styles here
  .mySlides {
    display: none;
    text-align: center;
  }
  .hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #ffffff;
    padding: 0px 4px 0px 4px;
  }
  @media (max-width: 820px) {
    .hover-underline-animation {
      margin: 0px 25px 0px 0px;
    }
  }
  @media (max-width: 430px) {
    .hover-underline-animation {
      margin: 0px 25px 0px 0px;
    }
  }

  .hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  .closeButton {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
  }

  .w3-content {
    /* background: rgba(25, 74, 140, 1); */
    background-color: #05a7be;
    max-width: 100vw;
    margin-top: -60px;
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 820px) {
    .w3-content {
      height: 11vh;
    }
  }
  @media (max-width: 430px) {
    .w3-content {
      height: 14vh;
    }
  }
  a {
    text-decoration: none;
    list-style: none;
    color: #fff;
  }
  position: fixed;
  width: 100vw;
  top: 19%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: ${(props) => props.opacity};
  transition: opacity 0.5s;

  .closeButton {
    position: absolute;
  }

  a {
    z-index: 2;
  }

  @media (max-width: 820px) {
    .closeButton {
      /* top: 50px; */
      right: 0;
      transform: translate(-50%, -50%);
    }
  }
  @media (max-width: 430px) {
    .closeButton {
      /* top: 50px; */
      right: 0;
      transform: translate(-50%, -50%);
    }
  }
`

export const AppModalSlider = (props: ModalProps) => {
  const [active] = useState(true)
  const [paused, setPaused] = useState(false)
  const [opacity, setOpacity] = useState(1)
  const slideIndexRef = useRef(0)
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY
      const newOpacity = Math.max(1 - scrollTop / 300, 0) // Adjust the divisor to control fade speed
      setOpacity(newOpacity)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (props.showModal) {
      startCarousel()
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [props.showModal, paused])

  const startCarousel = () => {
    const slides = document.getElementsByClassName('mySlides')

    const showSlides = () => {
      if (paused) return

      for (let i = 0; i < slides.length; i++) {
        ;(slides[i] as HTMLElement).style.display = 'none'
      }

      slideIndexRef.current++
      if (slideIndexRef.current > slides.length) {
        slideIndexRef.current = 1
      }

      if (slides[slideIndexRef.current - 1]) {
        ;(slides[slideIndexRef.current - 1] as HTMLElement).style.display = 'block'
      }

      timeoutRef.current = setTimeout(showSlides, 4000)
    }

    showSlides()
  }

  const setModalClose = () => {
    const body = document.getElementsByTagName('body')[0]
    body.style.overflow = 'auto'
    props.setShowModal(false)
  }

  const handleMouseEnter = () => {
    setPaused(true)
  }

  const handleMouseLeave = () => {
    setPaused(false)
  }

  return (
    <AppModalSliderWrapper opacity={opacity}>
      {props.showModal ? (
        <div className="sectionModal">
          {active ? (
            <div className="w3-content" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <div className="mySlides w3-container ">
                <p className="hover-underline-animation">
                  <a
                    href="https://spendmatters.com/2024/09/05/transforming-contract-management-ai-efficiency-precision/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Transforming contract management: From digital foundations to AI-powered efficiency and precision
                  </a>
                </p>
              </div>
              <div className="mySlides w3-container ">
                <p className="hover-underline-animation">
                  <a href="https://www.cioreview.com/raindrop" target="_blank" rel="noopener noreferrer">
                    CIO Review lists Raindrop as the Most Promising Procurement Solutions Provider
                  </a>
                </p>
              </div>
              <div className="mySlides w3-container ">
                <p className="hover-underline-animation">
                  <a
                    href="https://www.linkedin.com/feed/update/urn:li:activity:7227003364293623808/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Gartner Hype Cycle Features Raindrop
                  </a>
                </p>
              </div>
              <div className="mySlides w3-container ">
                <p className="hover-underline-animation">
                  <a href="https://www.wipro.com/" target="_blank" rel="noopener noreferrer">
                    Raindrop is proud to partner with Wipro
                  </a>
                </p>
              </div>
              <div className="mySlides w3-container ">
                <p className="hover-underline-animation">
                  <a
                    href="https://console.cloud.google.com/marketplace/product/raindrop-public/www.raindrop.com?hl=en&project=swift-smoke-278916"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Raindrop delivers Enterprise Spend Management through Google Cloud Marketplace
                  </a>
                </p>
              </div>

              <button onClick={setModalClose} className="closeButton">
                <svg
                  fill="#ffffff"
                  height="15px"
                  width="15px"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="-49 -49 588.00 588.00"
                  xmlSpace="preserve"
                  stroke="#ffffff"
                  strokeWidth="0.0049"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    stroke="#fffff"
                    strokeWidth="49"
                  >
                    {' '}
                    <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337 " />{' '}
                  </g>

                  <g id="SVGRepo_iconCarrier">
                    {' '}
                    <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337 " />{' '}
                  </g>
                </svg>
              </button>
            </div>
          ) : null}
        </div>
      ) : null}
    </AppModalSliderWrapper>
  )
}
