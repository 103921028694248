import React from 'react'
import { Layout } from '../components/AppLayout'
import { AppResourcesBanner } from '../components/AppResourcesBanner'
import { RDHelmet } from '../components/RDHelmet'
import AppTabPanel from '../components/AppTabPanel'

const resources = () => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '408-352-5162', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' 226 Airport Parkway Suite 250',
      addressRegion: ' San Jose, California 95110',
      postalCode: '95110',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }
  return (
    <Layout>
      <RDHelmet
        subtitle={'resources'}
        contentDescription="Explore valuable resources to enhance your business knowledge and success."
        contentKeywords="content='Business resources, Knowledge base, Guides, Tutorials, Whitepapers, Case studies, Webinars, Industry insights, Best practices, Learning center, Videos, Articles, Blogs, News, Podcasts, '"
        contentOG="AI Powered Source-to-Pay SaaS Platform | Try Raindrop Free"
        contentOgDescription="Explore valuable resources to enhance your business knowledge and success."
        contentOgUrl="https://raindrop.com/resources"
        schemaMarkup={schemaMarkup}
      />
      <AppResourcesBanner />
      <AppTabPanel />
    </Layout>
  )
}

export default resources
