import React from 'react'
import styled from 'styled-components'
import { AppVideoBanner } from './AppVideoBanner'
import WWOFSourcingData from '../Data/WWOFSourcingData.json'

const AppWWOFSourcingWapper = styled.div`
  background-color: #102c64;
  max-width: 95vw;
  margin: auto;
  border-radius: 42px;

  .WWOFcontainSection {
    display: flex;
    max-width: 1920px;
    margin: auto;
    padding: 50px 30px 50px 30px;
    justify-content: space-between;
    align-items: center; /* Align items vertically */
    overflow: hidden;
    margin-bottom: 60px;
    flex-direction: row-reverse; /* Moves image/video to the left and content to the right */
    gap: 30px; /* Adds space between image/video and content */
    @media (max-width: 1391px) {
      padding: 50px 20px 50px 20px;
    }
    @media (max-width: 1090px) {
      padding: 30px 15px;
      justify-content: center;
      flex-direction: column; /* Stack items for smaller screens */
      gap: 20px; /* Smaller gap for mobile view */
    }
  }

  .WWOFBannerSectionOne {
    margin-top: 2rem;
    margin-bottom: auto;
    flex: 1; /* Ensure it takes up equal space */
    @media (max-width: 1390px) {
      margin-top: auto;
    }
  }

  .WWOFHeadingSize {
    color: #f1f1f1;
    text-align: left;
    max-width: 780px;
    line-height: 1.30435em;
    padding-bottom: 3%;
    font-size: 2rem;
    letter-spacing: 0.1rem;
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  .WWOFBannerPara {
    color: #f1f1f1;
    max-width: 950px;
    padding: 10px 20px 0px 0px;
  }

  .WWOFBannerImage {
    border: 2px solid #34a1d5;
    border-radius: 40px;
    width: 100%;
    height: auto;
    flex: 1; /* Ensure it takes up equal space */
    float: none; /* Remove float for better layout */
    @media (max-width: 1390px) {
      width: 100%;
      float: unset;
    }
  }

  .WWOFRaindropListView {
    display: none;
  }

  .WWOFSectionButton {
    display: none;
  }

  .WWOFBannerVideoSection {
    width: 65%;
    flex: 1; /* Ensure it takes up equal space */
    @media (max-width: 1390px) {
      width: 100%;
    }
  }

  .WWOFBannerContent {
    flex: 1; /* Ensure it takes up equal space */
    @media (max-width: 1650px) {
      margin-top: unset;
    }
  }

  .WWOFSourcingHeadingby {
    color: #f1f1f1;
    margin-top: 60px;
    text-align: center;
    padding: 0% 0% 2% 0%;
  }

  .WWOFSectionButton {
    display: flex;
    justify-content: center;
    margin: 40px 0px 20px 0px;
  }
`

export const AppWWOFSourcing = () => {
  return (
    <AppWWOFSourcingWapper>
      {WWOFSourcingData.map((data, index) => {
        return (
          <div key={index}>
            <AppVideoBanner
              containSection="WWOFcontainSection"
              EventBannerHeading={data.heading}
              paraTwo={data.paraTwo}
              ImagePath={data.ImagePath}
              altName={data.AltName}
              ButtonContent={data.ButtonContent}
              InternalPageShiftUrl={data.ShiftInternalPath}
              ButtonStyle="WWOFSectionButton"
              BannerSectionOne="WWOFBannerSectionOne"
              BannerSectionTwo="BannerSectionTwo"
              EventBannerHeadingSize="WWOFHeadingSize"
              EventBannerPara="WWOFBannerPara"
              EventBannerImage="WWOFBannerImage"
              UlStyle="WWOFRaindropListView"
              EventBannerSectionTwo="WWOFBannerVideoSection"
              EventBannerSectionOne="WWOFBannerContent"
            />
          </div>
        )
      })}
    </AppWWOFSourcingWapper>
  )
}
