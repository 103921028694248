import React from 'react'
import styled from 'styled-components'
import { ReUseHOneTag } from './ReUseHOneTag'

const AppGartnerBannerWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  height: auto;

  .GartnerBannerBannerSection {
    height: 40vw;
    display: flex;
    justify-content: center; /* Centers the content horizontally */
    align-items: center; /* Centers the content vertically */
    flex-direction: column; /* Stack content for better responsiveness */
    text-align: center;
    margin: 0 auto; /* Centers the section horizontally */
    background-image: url('https://storage.googleapis.com/raindroppublic/website_data/Gartner_banner_BG.jpg');
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }

  .GartnerBannerHeading {
    text-align: center;
    margin-top: 55px;
    font-size: 3rem;
    padding: 0px 80px 0px 80px;
    color: #f1f1f1;
    @media (max-width: 1920px) {
      font-size: 3.5rem;
    }
    @media (max-width: 1650px) {
      font-size: 3rem;
    }
    @media (max-width: 1090px) {
      font-size: 2rem;
    }
    @media (max-width: 770px) {
      font-size: 1.5rem;
    }
    @media (max-width: 480px) {
      padding-top: 15px;
      font-size: 1rem;
    }
  }
`

export const AppGartnerBanner = () => {
  return (
    <AppGartnerBannerWapper>
      <div className="GartnerBannerBannerSection">
        <div className="GartnerBannerBannerContentSection">
          <ReUseHOneTag
            Heading="Raindrop recognized in Gartner Hype Cycle for Sourcing and Procurement 2024"
            HeadingStyle="GartnerBannerHeading"
          />
        </div>
      </div>
    </AppGartnerBannerWapper>
  )
}
