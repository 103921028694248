import React from 'react'
import styled from 'styled-components'
import { ReUseHOneTag } from './ReUseHOneTag'
// import { ReuseImgTag } from './ReuseImgTag'
import SOCReportData from '../Data/SOCReportData.json'

const AppSOCReportWpper = styled.div`
  max-width: 1920px;
  margin: auto;
  .MainSCOsection {
    border-radius: 32px;
    background-image: url(/img/Resources-page-banner.png);
    background-color: #102c64;
    padding: 100px 96px 100px 96px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1080px) {
      flex-wrap: wrap;
      padding: 80px 15px 50px 15px;
      border-radius: 32px;
      background-color: #151936;
    }
  }
  .SOCBannerHeading {
    /* max-width: 650px; */
    line-height: 1.30435em;
    padding-bottom: 3%;
    padding-top: 2%;
    font-size: 2.5rem;
    color: #fff;
    /* @media (max-width: 1540px) {
      max-width: 750px;
    } */
    @media (max-width: 1080px) {
      padding-top: unset;
      max-width: unset;
    }
  }
  .HeadingSection {
    @media (max-width: 1080px) {
      margin: auto;
      order: 1;
    }
  }
  .ImageSection {
    @media (max-width: 1080px) {
      margin: auto;
      order: 0;
    }
  }
  .SCOBannerimage {
    width: 80%;
    height: auto;
    @media (max-width: 1080px) {
      margin-top: 0%;
      float: unset;
      width: 100%;
      justify-content: center;
    }
  }
  .SecurityCard {
    cursor: pointer;
  }
`

export const AppSOCReport = () => {
  return (
    <AppSOCReportWpper>
      {SOCReportData.map((data, index) => {
        return (
          <div className="MainSCOsection" key={index}>
            <div className="HeadingSection">
              <ReUseHOneTag Heading={data.title} HeadingStyle="SOCBannerHeading" />
            </div>
            <div className="ImageSection">
              {/* <ReuseImgTag ImageStyle="SCOBannerimage" ImagePath={data.ImagePath} AltName={data.AltName} /> */}
              <iframe
                src="https://securityscorecard.com/security-rating/badge/raindrop.com"
                width="256vw"
                height="100vh"
                frameBorder="0"
                className="SecurityCard"
              ></iframe>
            </div>
          </div>
        )
      })}
    </AppSOCReportWpper>
  )
}
