import React from 'react'
import styled from 'styled-components'

const SectionWrapper = styled.div`
  max-width: 1920px;
  margin: auto;
  width: 96vw;
  display: flex;
  flex-wrap: wrap;
  padding: 50px 20px;

  .content-section {
    flex: 1;
    min-width: 300px;
    padding-right: 20px;

    .image-container {
      text-align: center;

      img {
        max-width: 100%;
        height: auto;
        border: none;
      }
    }

    .description {
      color: #1f1f1f;
      font-size: 1rem;
      line-height: 1.5;

      @media (max-width: 1090px) {
        font-size: 0.9rem;
        padding-top: 8px;
      }
    }
  }

  .form-section {
    height: 60vh;
    flex: 1;
    min-width: 300px;
    max-width: 450px;
    color: #102c64;
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: #102c64;
    background-image: radial-gradient(#ffffff33 1px, #102c64 1px);
    background-size: 10px 10px;
    padding: 20px;
    margin-top: 12px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 8px;
      background: linear-gradient(90deg, #34a1d5, #98da5a);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    h3 {
      color: #fff;
      margin-bottom: 20px;
      text-align: center;
    }

    button {
      background-color: #34a1d5;
      color: white;
      border: none;
      border-radius: 42px;
      padding: 10px 20px;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #fff;
        color: #111;
      }
    }
  }

  .thank-you-section {
    height: 60vh;
    flex: 1;
    min-width: 300px;
    max-width: 450px;
    background: #fff;
    padding: 20px;
    margin-top: 12px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 8px;
      background: linear-gradient(90deg, #34a1d5, #49c269);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    h2 {
      color: #1f1f1f;
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    p {
      color: #1f1f1f;
      font-size: 1rem;
      margin-bottom: 20px;
    }

    button {
      background-color: #34a1d5;
      color: white;
      border: none;
      border-radius: 42px;
      padding: 10px 20px;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #102c64;
      }
    }

    .support-text {
      margin-top: 60px;
      font-size: 0.8rem;
      color: #888;
    }
  }
`

export const AppGartnerSnapAndFormSection = () => {
  return (
    <SectionWrapper>
      <div className="content-section">
        <div className="description">
          <p>
            Procurement organizations can use emerging technology to deliver automation, efficiency, and better
            collaboration with suppliers and stakeholders. Procurement technology leaders should use this Hype Cycle to
            understand the maturity, adoption, and benefit of sourcing and procurement technologies.
          </p>
        </div>
        <div className="image-container">
          <img
            src="https://storage.googleapis.com/raindroppublic/website_data/Gratner-Figure-1.png"
            alt="Gartner Hype Cycle for Procurement and Sourcing Solutions 2024"
          />
        </div>
      </div>
      <div className="gradient">
        <div className="form-section">
          <h3>Download your free copy now!</h3>
          <button
            onClick={() => {
              window.location.href = 'https://share.hsforms.com/1bei4fFK9R32ZjExUJWB5LA4702y'
            }}
          >
            Download Now
          </button>
        </div>
      </div>
    </SectionWrapper>
  )
}
