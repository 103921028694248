import React from 'react'
import styled from 'styled-components'
import { Block } from './Block'
import SolutionFinanceBannerData from '../Data/SolutionFinanceBannerData.json'
import { Button } from './Button'
import { AppFinanceTable } from './AppFinanceTable'
import { LinkHubspot } from './LinkHubspot'

const AppFinanceBannerWapper = styled.div`
  .FinancemainContain {
    max-width: 1920px;
    margin: auto;
  }
  .FinanceSection {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1090px) {
      flex-flow: wrap;
    }
  }
  .FinanceHeadingSmall {
    display: none;
    @media (max-width: 1090px) {
      text-align: center;
      display: block;
      padding-bottom: 3%;
    }
  }
  .FinanceHeadingBig {
    margin: auto;
    padding-bottom: 3%;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .FinanceParaOne {
    max-width: 920px;
    color: #047cc2;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 15px 80px 15px 0px;
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
    }
  }
  .FinancePara {
    max-width: 920px;
    padding: 0px 80px 15px 0px;
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
    }
  }
  .FinanceDownPara {
    max-width: 920px;
    padding: 3rem 80px 0px 0px;
    color: #047cc2;
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
    }
  }
  .FinanceImage {
    height: auto;
    padding-left: 5rem;
    @media (max-width: 1090px) {
      margin: auto;
      padding-left: unset;
    }
  }
  .FinanceImageCenter {
    width: 75%;
    @media (max-width: 1090px) {
      width: 100%;
    }
  }
  .FinanceparaSection {
    margin-top: 6rem;
    @media (max-width: 1090px) {
      margin: auto;
    }
  }
  .iconColor {
    display: none;
  }
  .buttonDisplay {
    display: none;
  }
  .FreeButton {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
  .FinanceDisplay {
    display: none;
  }
`

export const AppFinanceBanner = () => {
  return (
    <AppFinanceBannerWapper>
      {SolutionFinanceBannerData.map((data, index) => {
        return (
          <div key={index}>
            <Block
              Heading={data.Title}
              paraOne={data.paraOne}
              paraTwo={data.paraTwo}
              paraThree={data.paraThree}
              ImagePath={data.ImagePath}
              mainContain="FinancemainContain"
              containHeading="FinanceHeadingSmall"
              containHeadingBig="FinanceHeadingBig"
              containParaOne="FinanceParaOne"
              containParaTwo="FinancePara"
              containParaFour="FinanceDisplay"
              containParaFive="FinanceDisplay"
              containParaThree="FinanceDownPara"
              InternalSection="FinanceSection"
              containImage="FinanceImage"
              altName={data.AltName}
              paraSection="FinanceparaSection"
              IconColor="iconColor"
              ButtonDisplay="buttonDisplay"
              allImage="FinanceImageCenter"
            />
          </div>
        )
      })}
      <div>
        <AppFinanceTable />
      </div>
      <div className="FreeButton">
        <LinkHubspot to="https://share.hsforms.com/15ZhMdunrTji6Z5_wWjrDyA4702y">
          <span className="sr-only">Jump to packages</span>
          <Button
            cta="Click here to get started."
            label="Click here to get started."
            type="submit"
            className="demobutton"
          />
        </LinkHubspot>
      </div>
    </AppFinanceBannerWapper>
  )
}
