import React from 'react'
import styled from 'styled-components'
// import { ReUsePtag } from './ReUsePtag'
// import { ReUsePtagwithAnchorTag } from './ReUsePtagwithAnchorTag'
import { ReuseImgTag } from './ReuseImgTag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import { LinkHubspot } from './LinkHubspot'
// import ReourcesCardEmailData from '../Data/ReourcesCardEmailData.json'
// import { Button } from './Button'
import { ResourcesPageButton } from './ResourcesPageButton'

const AppReourcesCardFrontWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .MainReourcesCardsection {
    padding: 50px 30px 50px 30px;
    @media (max-width: 1090px) {
      padding: 0px 15px 2rem 15px;
    }
  }
  .CardReourcesSection {
    display: flex;
    max-width: 1590px;
    justify-content: space-evenly;
    margin: auto;

    @media (max-width: 7680px) {
      max-width: 1590px;
      flex-wrap: wrap;
    }
  }
  .ReourcesCardsection {
    width: 400px;
    height: auto;
    box-shadow: 2px 4px 10px 0 rgb(0 0 0 / 10%);
    border: 1px solid #34a1d5;
    margin: 20px 0px 20px 0px;
    border-radius: 12px;
  }
  .ReourcesCardsection:hover {
    box-shadow: rgb(160 161 163 / 88%) 0px 3px 7px 0px;
    border: 2px solid #34a1d5;
  }
  .CardReourcesContentSection {
    display: flex;
    /* height: 46%; */
    flex-direction: column;
    height: 40%;
    justify-content: space-between;
    /* margin-top: 30px; */
    /* margin-top: 30px; */
  }
  .ReourcesCardDatePara {
    font-size: 1rem;
    padding: 0px 10px 0px 10px;
    /* color: #34a1d5; */
  }
  .ReourcesCardHeading {
    font-size: 21px;
    padding: 0px 10px 0px 10px;
    margin-bottom: 0;
    color: #1f1f1f;
  }
  .ReourcesCardPara {
    font-size: 1rem;
    padding: 0px 10px 0px 10px;
  }
  .ReourcesCardImage {
    width: 100%;
    height: 280px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
  .ButtonSectionReourcesCard {
    /* margin-top: 20px; */
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }
  /* .ReourcesCardButton {
    color: #fff;
    outline: none;
    padding: 0px 10px 0px 10px;
    font-size: 1.2rem;
    background-color: #34a1d5;
    border: none;
    border-radius: 12px;
    height: 50px;
  } */
  .ReourcesCardButton:hover {
    color: rgb(4, 124, 194);
    text-decoration: dotted;
  }
  .CardLinkHub {
    text-decoration: none;
  }
  .achorPara {
    font-size: 1rem;
    font-weight: bold;
    color: #34a1d5;
    padding-left: 6px;
    padding-right: 6px;
  }
  /* .demobutton{
    display: inline-block;
    margin-top: auto;
    align-self: flex-start;
  } */
`

export const AppReourcesCardFront = (props: { ResourcesCardEmailData: any[] }) => {
  return (
    <AppReourcesCardFrontWapper>
      <div className="MainReourcesCardsection">
        <div className="CardReourcesSection">
          {props.ResourcesCardEmailData.map((data, index) => {
            return (
              <div className="ReourcesCardsection" key={index}>
                <div className="">
                  <ReuseImgTag ImageStyle="ReourcesCardImage" ImagePath={data.ImagePath} AltName="" />
                </div>
                <div className="CardReourcesContentSection">
                  <ReUseHTwotag Heading={data.title} HeadingStyle="ReourcesCardHeading" />
                  {/* <ReUsePtag para={data.DatePara} paraStyle="ReourcesCardDatePara" /> */}
                  {/* <ReUsePtagwithAnchorTag
                    ParaLeft={data.EmailParaLeft}
                    anchorPara={data.WithAnchorPara}
                    ParaRight={data.EmailParaRight}
                    href={data.ExternalPageShiftUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    anchorStyle="achorPara"
                    paraStyle="ReourcesCardPara"
                  /> */}
                  <div className="ButtonSectionReourcesCard">
                    <LinkHubspot to={data.InternalPageShiftUrl} className="CardLinkHub">
                      <span className="sr-only">Jump to packages</span>
                      <ResourcesPageButton
                        cta={data.eventButtonName}
                        label="Read More"
                        type="submit"
                        className="demobutton"
                      />
                    </LinkHubspot>
                  </div>
                  {/* <div className="ButtonSection">
                  <LinkHubspot to="/Events/Register" className='CardLinkHub'>
                    <span className='sr-only'>Jump to packages</span>
                    <Button cta='Register Now' label='Register Now' type='submit' className='demobutton' />
                  </LinkHubspot>
                  </div> */}
                </div>
                {/* <img className='wave-background' src={SignUpData.ImagePath} /> */}
              </div>
            )
          })}
        </div>
      </div>
    </AppReourcesCardFrontWapper>
  )
}
