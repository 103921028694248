import React from 'react'
import styled from 'styled-components'
import { Block } from './Block'
import SolutionFreeBannerData from '../Data/SolutionFreeBannerData.json'
import { Button } from './Button'
import { LinkHubspot } from './LinkHubspot'
import { ReUseHTwotag } from './ReUseHTwoTag'
import { ReUsePtag } from './ReUsePtag'

const AppFreeBannerWapper = styled.div`
  .mainContain {
    max-width: 1920px;
    margin: auto;
  }
  .FreeSection {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1090px) {
      flex-flow: wrap;
    }
  }
  .containHeadingSmall {
    display: none;
    @media (max-width: 1090px) {
      text-align: center;
      display: block;
      padding-bottom: 3%;
    }
  }
  .containHeadingBig {
    margin: auto;
    padding-bottom: 3%;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .FreePara {
    max-width: 825px;
    padding: 15px 35px 15px 0px;
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
    }
  }
  .FreeParaOne {
    max-width: 825px;
    color: #047cc2;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 15px 35px 15px 0px;
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
    }
  }
  .FreeImage {
    height: auto;
    padding-left: 5rem;
    @media (max-width: 1090px) {
      width: auto;
      padding-left: unset;
      margin: auto;
    }
  }
  .FreeImageCenter {
    height: auto;
    width: 75%;
    @media (max-width: 1090px) {
      margin: auto;
      display: flex;
      width: 100%;
    }
  }
  .paraSection {
    margin-top: 1%;
    margin: auto;
  }
  .iconColor {
    display: none;
  }
  .buttonDisplay {
    display: none;
  }
  .FreeButton {
    display: flex;
    /* margin: auto; */
    justify-content: flex-end;
    padding-right: 20rem;
    padding-bottom: 5rem;
    @media (max-width: 1321px) {
      padding-right: unset;
      padding-bottom: 3rem;
      justify-content: center;
    }
  }
  .MainHeadingby {
    text-align: center;
    padding-bottom: 3rem;
  }
  .UpperPara {
    text-align: left;
    padding: 0rem 5rem 3rem 5rem;
    @media (max-width: 1321px) {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
  .FreeDisplay {
    display: none;
  }
`

export const AppFreeBanner = () => {
  return (
    <AppFreeBannerWapper>
      <div id="test123">
        <ReUseHTwotag Heading="By Company Size" HeadingStyle="MainHeadingby" />
        <ReUsePtag
          para="Enterprises face different challenges depending upon industry and company size.  Raindrop has you covered and scales across your enterprise with seamless tools as your company grows."
          paraStyle="UpperPara"
        />
      </div>
      {SolutionFreeBannerData.map((data, index) => {
        return (
          <div key={index}>
            <Block
              id={data.id}
              Heading={data.Title}
              paraOne={data.paraOne}
              paraTwo={data.paraTwo}
              paraThree={data.paraThree}
              ImagePath={data.ImagePath}
              mainContain="mainContain"
              containHeading="containHeadingSmall"
              containHeadingBig="containHeadingBig"
              containParaOne="FreeParaOne"
              containParaTwo="FreePara"
              containParaThree="FreePara"
              containParaFour="FreeDisplay"
              containParaFive="FreeDisplay"
              InternalSection="FreeSection"
              containImage="FreeImage"
              altName={data.AltName}
              paraSection="paraSection"
              IconColor="iconColor"
              ButtonDisplay="buttonDisplay"
              allImage="FreeImageCenter"
            />
          </div>
        )
      })}
      <div className="FreeButton">
        <LinkHubspot to="https://share.hsforms.com/15ZhMdunrTji6Z5_wWjrDyA4702y">
          <span className="sr-only">Jump to packages</span>
          <Button
            cta="Click here to get started."
            label="Click here to get started."
            type="submit"
            className="demobutton"
          />
        </LinkHubspot>
      </div>
    </AppFreeBannerWapper>
  )
}
