import React, { useRef } from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReuseImgTag } from './ReuseImgTag'
import { ReUseHTwotag } from './ReUseHTwoTag'
// import { FaLinkedin } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import AdvisorBioData from '../Data/AdvisorBioData.json'

const AppAdvisorModelWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .backgroundSection {
    width: 100vw;
    height: 100vh;
    background-color: #102c64;
    display: flex;
    justify-content: space-evenly;
    position: fixed;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: scroll;
  }
  .SectionBackGraound {
    background: #fff;
    /* height: 80vh; */
    margin: auto;
    border-radius: 25px;
    @media (max-width: 1450px) {
      /* height: 90vh; */
      width: 90vw;
      margin-top: 80px;
    }
    @media (max-width: 450px) {
      margin-top: 100px;
    }
    /* @media (max-width: 600px) {
      height: 165vh;
      margin-bottom: 80px;
    }
    @media (max-width: 420px) {
      height: 185vh;
    }
    @media (max-width: 340px) {
      height: 320vh;
    } */
  }
  .MainSection {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
    }
  }
  .ImageBIG {
    width: 300px;
    height: 290px;
    border: solid 2px #34a1d5;
    border-radius: 50%;
    margin-left: 35px;
    margin-right: 35px;
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: #fff;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .MobileImageBIG {
    display: none;
    width: 275px;
    height: 260px;
    background-color: #fff;
    border: solid 2px #34a1d5;
    border-radius: 50%;
    margin: auto;
    @media (max-width: 1090px) {
      display: flex;
    }
  }
  .HeadingName {
    text-align: left;
    @media (max-width: 1090px) {
      margin-top: -8%;
      text-align: center;
    }
  }
  .HeadingDES {
    text-align: left;
    color: #34a1d5;
    padding-right: 15px;
    font-size: 22px;
    max-width: 950px;
    line-height: 2rem;
    @media (max-width: 1090px) {
      text-align: center;
      padding-right: 10px;
      padding-left: 10px;
    }
  }
  .UpperParaOne {
    max-width: 950px;
    text-align: left;
    padding-right: 20px;
    @media (max-width: 1090px) {
      padding: 0px 15px 0px 15px;
    }
  }
  .MediaIconViewSection {
    display: flex;
    justify-content: flex-start;
    @media (max-width: 1090px) {
      justify-content: center;
    }
  }
  .ButtonSection {
    display: flex;
    justify-content: flex-end;
  }
  .CloseButton {
    width: 40px;
    height: 40px;
    color: #a6a7a8;
    margin: 20px 20px 0px 20px;
  }
  .SecondParaOne {
    padding: 50px 20px 20px 20px;
    max-width: 1350px;
    text-align: left;
    display: flex;
    margin: auto;
    @media (max-width: 1090px) {
      padding: 15px 15px 0px 15px;
      /* display: none; */
    }
  }
  .DowmParaOne {
    padding: 10px 20px 50px 20px;
    max-width: 1350px;
    display: flex;
    text-align: left;
    margin: auto;
    @media (max-width: 1090px) {
      padding: 30px 15px 30px 15px;
      /* display: none; */
    }
    @media (max-width: 450px) {
      padding: 30px 15px 50px 15px;
      /* display: none; */
    }
  }
  .LinkedinIcon {
    color: #a6a7a8;
    width: 45px;
    height: 45px;
    margin: 15px 15px 0 15px;
  }
`

export const AppAdvisorModel = (props: any) => {
  const ModalRef = useRef()

  const setBioClose = () => {
    const body = document.getElementsByTagName('body')[0]
    body.style.overflow = 'auto'
    props.setAdvisorShowBio(false)
  }

  const closeModal = (e: any) => {
    const body = document.getElementsByTagName('body')[0]
    body.style.overflow = 'auto'
    if (ModalRef.current === e.target) {
      props.setAdvisorShowBio(false)
    }
  }
  const data = AdvisorBioData[props.idx]

  return (
    <AppAdvisorModelWapper>
      {props.showAdvisorBio && data ? (
        <div className="backgroundSection" onClick={closeModal} ref={ModalRef}>
          <div className="SectionBackGraound">
            <div className="MainSection">
              <div>
                <ReuseImgTag ImageStyle="ImageBIG" ImagePath={data.ImagePath} AltName={data.AltName} />
              </div>
              <div>
                <div className="ButtonSection">
                  <MdClose onClick={setBioClose} className="CloseButton" />
                </div>
                <ReUseHTwotag Heading={data.title} HeadingStyle="HeadingName" />
                <ReuseImgTag ImageStyle="MobileImageBIG" ImagePath={data.ImagePath} AltName={data.AltName} />
                <ReUseHTwotag Heading={data.DECtitle} HeadingStyle="HeadingDES" />
                <ReUsePtag para={data.paraOne} paraStyle="UpperParaOne" />
                {/* <div className='MediaIconViewSection'>
                  <a href={data.profileURL} target='_blank' rel='noopener noreferrer'>
                    <FaLinkedin className='LinkedinIcon' />
                  </a>
                </div> */}
              </div>
            </div>
            {/* <ReUsePtag para={data.paraTwo} paraStyle='SecondParaOne' />
            <ReUsePtag para={data.paraThree} paraStyle='DowmParaOne' /> */}
          </div>
        </div>
      ) : null}
    </AppAdvisorModelWapper>
  )
}
