import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import ResourcesCardVideosData from '../Data/ResourcesCardVideosData.json'
import AppCustomerTestimonialsVideos from './AppCustomerTestimonialsVideos'
import AppProductVideos from './AppProductVideos'
import AppCustomerStoriesVideos from './AppCustomerStoriesVideos'

const AppTabPanelResourcesVideosCardFrontWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .MainReourcesCardsection {
    padding: 0px 30px 50px 30px;
    @media (max-width: 1090px) {
      padding: 0px 15px 2rem 15px;
    }
  }
  .CardReourcesSection {
    display: flex;
    max-width: 1590px;
    justify-content: space-evenly;
    margin: auto;

    @media (max-width: 7680px) {
      max-width: 1590px;
      flex-wrap: wrap;
    }
  }
  .ReourcesCardsection {
    width: 400px;
    height: auto;
    box-shadow: 2px 4px 10px 0 rgb(0 0 0 / 10%);
    border: 1px solid #34a1d5;
    margin: 20px 0px 20px 0px;
    border-radius: 12px;
  }
  .ReourcesCardsection:hover {
    box-shadow: rgb(160 161 163 / 88%) 0px 3px 7px 0px;
    border: 2px solid #34a1d5;
  }
  .CardReourcesContentSection {
    display: flex;

    flex-direction: column;
    height: 30%;
    justify-content: space-between;
  }
  .ReourcesCardDatePara {
    font-size: 1rem;
    padding: 0px 10px 0px 10px;
  }
  .ReourcesCardHeading {
    font-size: 21px;
    padding: 0px 10px 0px 10px;
    margin-bottom: 0;
    color: #1f1f1f;
  }
  .ReourcesCardPara {
    font-size: 1rem;
    padding: 0px 10px 0px 10px;
  }
  .ReourcesCardImage {
    width: 100%;
    height: 280px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    border: none;
  }
  .ButtonSectionReourcesCard {
    margin-bottom: 45px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .ReourcesCardButton:hover {
    color: rgb(4, 124, 194);
    text-decoration: dotted;
  }
  .CardLinkHub {
    text-decoration: none;
  }
  .achorPara {
    font-size: 1rem;
    font-weight: bold;
    color: #34a1d5;
    padding-left: 6px;
    padding-right: 6px;
  }
  .tabcontentPlatformcategoryVideos {
    margin-left: 72px;
    /* border-bottom: 2px solid #34a1d5; */
    width: 279px;
    padding-bottom: 4px;
    /* border-bottom: 2px solid #34a1d5; */
    /* font-weight: bolder; */
    @media (max-width: 468px) {
      margin-left: 16px;
    }
  }
  .tabcontentCustomercategoryVideos {
    margin-left: 72px;
    /* border-bottom: 2px solid #34a1d5; */
    width: 258px;
    padding-bottom: 4px;
    /* border-bottom: 2px solid #34a1d5; */
    /* font-weight: bolder; */
    @media (max-width: 468px) {
      margin-left: 16px;
    }
  }
  .tabcontentTutorialcategoryVideos {
    margin-left: 72px;
    /* border-bottom: 2px solid #34a1d5; */
    width: 252px;
    padding-bottom: 4px;
    /* border-bottom: 2px solid #34a1d5; */
    /* font-weight: bolder; */
    @media (max-width: 468px) {
      margin-left: 16px;
    }
  }
`

export const AppTabPanelResourcesVideosCardFront = () => {
  return (
    <AppTabPanelResourcesVideosCardFrontWapper>
      <h2 className="tabcontentCustomercategoryVideos">ThinkTank Series</h2>
      <div className="MainReourcesCardsection">
        <div className="CardReourcesSection">
          {ResourcesCardVideosData.map((data, index) => {
            return (
              <div className="ReourcesCardsection" key={index}>
                {/* <div className="">
                  <video className="ReourcesCardImage" controls muted loop src={data.ImagePath} />
                </div> */}
                <div className="WelcomeButtonSection">
                  <iframe
                    src={data.ImagePath}
                    className="ReourcesCardImage"
                    // frameborder="0"
                    allow="autoplay; fullscreen"
                    // allowfullscreen
                  />
                </div>
                <div className="CardReourcesContentSection">
                  <ReUseHTwotag Heading={data.title} HeadingStyle="ReourcesCardHeading" />
                  <ReUsePtag para={data.DatePara} paraStyle="ReourcesCardDatePara" />
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <h2 className="tabcontentPlatformcategoryVideos">Customer Stories</h2>
      <AppCustomerStoriesVideos />
      <h2 className="tabcontentPlatformcategoryVideos">Platform Overview</h2>
      <AppCustomerTestimonialsVideos />
      <h2 className="tabcontentTutorialcategoryVideos">Life at Raindrop</h2>
      <AppProductVideos />
    </AppTabPanelResourcesVideosCardFrontWapper>
  )
}
