import styled from 'styled-components'
import React from 'react'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import ResourcesCardTestimonialVideosData from '../Data/ResourcesCardTestimonialVideosData.json'

const AppCustomerTestimonialsVideosWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .tab {
    display: flex;
    justify-content: center;
    overflow: hidden;
    /* border-bottom: 1px solid #ccc; */
    /* background-color: #f1f1f1; */
  }

  /* Style the buttons inside the tab */
  .tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
  }

  /* Change background color of buttons on hover */
  .tab button:hover {
    border-bottom: 2px solid #34a1d5;
    font-weight: bolder;
  }

  /* Create an active/current tablink class */
  .tab button.active {
    border-bottom: 2px solid #34a1d5;
    font-weight: bolder;
  }

  /* Style the tab content */
  .tabcontent {
    display: none;
    padding: 6px 12px;
    border-top: none;
  }
  .TabPanel {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }
  .tabcontentcategory {
    margin-left: 72px;
    border-bottom: 2px solid #34a1d5;
    width: 05%;
    padding-bottom: 4px;
    /* border-bottom: 2px solid #34a1d5; */
    /* font-weight: bolder; */
    @media (max-width: 468px) {
      margin-left: 16px;
    }
  }
  .CardBTN {
    background-color: #34a1d5;
    color: #fff;
    padding: 10px 30px 10px 30px;
    border: none;
    height: 50px;
    border-radius: 25px;
    font-size: 18px;
    margin-bottom: 30px;
  }

  .CardBTN:hover {
    background-color: #047cc2;
    color: #fff;
    transition-duration: 0.3s;
    transition-timing-function: linear;
    transition-delay: 0s;
  }
  .BTNContainer {
    display: flex;
    justify-content: center;
  }
`

const AppCustomerTestimonialsVideos = () => {
  return (
    <AppCustomerTestimonialsVideosWapper>
      <div className="MainReourcesCardsection">
        <div className="CardReourcesSection">
          {ResourcesCardTestimonialVideosData.map((data, index) => {
            return (
              <div className="ReourcesCardsection" key={index}>
                {/* <div className="">
                  <video className="ReourcesCardImage" controls muted loop src={data.ImagePath} />
                </div> */}
                <div className="WelcomeButtonSection">
                  <iframe
                    src={data.ImagePath}
                    className="ReourcesCardImage"
                    // frameborder="0"
                    allow="autoplay; fullscreen"
                    // allowfullscreen
                  />
                </div>
                <div className="CardReourcesContentSection">
                  <ReUseHTwotag Heading={data.title} HeadingStyle="ReourcesCardHeading" />
                  <ReUsePtag para={data.DatePara} paraStyle="ReourcesCardDatePara" />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </AppCustomerTestimonialsVideosWapper>
  )
}

export default AppCustomerTestimonialsVideos
