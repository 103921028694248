import React from 'react'
import styled from 'styled-components'

export const SignUpAltButtonWrapper = styled.button`
  position: fixed;
  right: 0%;
  top: 50%;
  transform: translate(0%, -50%);
  padding: 20px 0; /* Padding adjusted for vertical alignment */
  text-align: center;
  font-family: 'GothamHTF', 'Helvetica Neue', sans-serif;
  color: #fff;
  letter-spacing: 0.1rem;
  text-transform: capitalize;
  text-decoration: none;
  box-sizing: border-box;
  background: #34a1d5;
  transition: background 0.3s linear;
  /* background-size: 400%; */
  border: none;
  z-index: 1;
  width: 50px; /* Set width suitable for the vertical shape */
  /* height: fit-content; */
  border-radius: 25px 0 0 25px; /* Rounded left corners */

  &:focus {
    outline: none;
  }

  &:hover {
    background: #004991;
    transition: background 0.3s ease-in;
    cursor: pointer;
    animation: animate 8s linear infinite;

    &::before {
      filter: blur(20px);
      opacity: 1;
      animation: animate 8s linear;
    }
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    background-size: 400%;
    border-radius: 25px 0 0 25px;
    opacity: 0;
    transition: 0.5s;
  }

  @keyframes animate {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 400%;
    }
  }

  .SignUpAltButtontext {
    transform: rotate(180deg); /* Rotate the text */
    transform-origin: center; /* Set rotation point to center */
    white-space: nowrap; /* Prevents text from wrapping */
    background-color: transparent;
    align-items: center;
    font-size: 22px;
    color: white;
    margin: 0;
  }
`

export const SignUpAltButton = (props: any) => {
  return (
    <SignUpAltButtonWrapper aria-label={props.label} type={props.type} onClick={props.onClick}>
      <span className="SignUpAltButtontext">{props.cta}</span>
    </SignUpAltButtonWrapper>
  )
}
