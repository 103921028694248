import styled from 'styled-components'
import React from 'react'

const AppHomeAboutCardsWrapper = styled.div`
  margin-bottom: 50px;
  main {
    display: grid;
    grid-template-columns: 250px 350px 400px 300px;
    grid-template-rows: 250px 250px;
    grid-template-areas:
      'security experience middle top-right'
      'total-solution total-solution middle bottom-right';
    gap: 10px;
    justify-content: center;
    padding: 50px;
    font-family: 'Roboto', sans-serif;
    /* max-width: 1440px; */
    width: 96vw;
    margin: 0 auto;
    background-color: #102c64;
    border-radius: 24px;

    @media (max-width: 1344px) {
      grid-template-columns: repeat(2, 1fr); /* Two columns on smaller screens */
      grid-template-rows: auto;
      grid-template-areas: none; /* Remove named grid areas */
      padding: 20px;
      gap: 10px;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr; /* One column on mobile */
      grid-template-rows: auto;
      padding: 10px;
      gap: 10px;
    }
  }

  .card {
    perspective: 1000px; /* Necessary for 3D effect */
    position: relative;
    width: 100%;
    height: auto; /* Ensure full width and auto height on smaller screens */
  }

  .card-content {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.8s ease;
    border-radius: 24px;
    box-shadow: rgba(226, 226, 226, 0.16) 0px 1px 4px;
  }

  .card:hover .card-content {
    transform: rotateY(180deg);
  }

  .front,
  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: rgba(226, 226, 226, 0.16) 0px 1px 4px;
  }

  .back {
    transform: rotateY(180deg);
    color: #6e6e6e;
  }

  /* Background colors for each card */
  .card.security .front,
  .card.security .back {
    background-color: #d4e6fd; /* Light blue */
  }

  .card.experience .front,
  .card.experience .back {
    background-color: #f6e3f3; /* Light pink */
  }

  .card.total-solution .front,
  .card.total-solution .back {
    background-color: #ececec; /* Light green */
  }

  .card.middle .front,
  .card.middle .back {
    background-color: #e3f6f7; /* Light yellow */
  }

  .card.top-right .front,
  .card.top-right .back {
    background-color: #e7effa; /* Light purple */
  }

  .card.bottom-right .front,
  .card.bottom-right .back {
    background-color: #f5f7d4; /* Light cream */
  }

  .card h2 {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: lighter;
    text-transform: uppercase;
    color: #1f1f1f;
    margin: 0;
    font-size: 24px;
    letter-spacing: 0.5px;
  }

  img.icon {
    position: absolute;
    right: 0;
    top: 20%;
    transform: translateY(-50%);
    height: 90px; /* Adjust icon size */
    width: auto; /* Maintain aspect ratio */
    margin-right: 0; /* Ensure no space on the right */
  }

  .card p {
    font-size: 18px;
    font-weight: lighter;
    color: #1f1f1f;
    text-align: center;
  }
  @media (min-width: 1344px) {
    /* Card area assignments for the layout */
    .card.security {
      grid-area: security;
    }

    .card.experience {
      grid-area: experience;
    }

    .card.total-solution {
      grid-area: total-solution;
    }

    .card.middle {
      grid-area: middle;
    }

    .card.top-right {
      grid-area: top-right;
    }

    .card.bottom-right {
      grid-area: bottom-right;
    }
  }
  @media (max-width: 1344px) {
    .card {
      margin-bottom: 20px; /* Add margin to prevent overlapping */
      width: 100%; /* Ensure cards are stacked and fully responsive */
      height: 30vh;
    }
  }

  @media (max-width: 768px) {
    .card {
      margin-bottom: 20px; /* Adjust for mobile spacing */
      width: 100%; /* Ensure full-width stacking for smaller screens */
      height: 30vh;
    }
  }
`

export const AppHomeAboutCards = () => {
  return (
    <AppHomeAboutCardsWrapper>
      <main>
        <div className="card security">
          <div className="card-content">
            <div className="front">
              <h2>Authentic Solutions</h2>
              <img
                className="icon"
                src="https://storage.googleapis.com/raindroppublic/website_data/Raindrop-Authentic-Solutions.png"
                alt="Security Icon"
              />
            </div>
            <div className="back">
              <p>
                Designed by Procurement, Technology, Finance, and Operations experts with decades of real-world industry
                experience.{' '}
              </p>
            </div>
          </div>
        </div>
        <div className="card experience">
          <div className="card-content">
            <div className="front">
              <h2>Interoperability</h2>
              <img
                className="icon"
                src="https://storage.googleapis.com/raindroppublic/website_data/Raindrop-Interoperability.png"
                alt="Experience Icon"
              />
            </div>
            <div className="back">
              <p>
                Raindrop's modern architecture enables integration with almost any other digital tool, enabling you to
                gain further value from your existing digital tools investments.
              </p>
            </div>
          </div>
        </div>
        <div className="card total-solution">
          <div className="card-content">
            <div className="front">
              <h2>Modern Technology</h2>
              <img
                className="icon"
                src="https://storage.googleapis.com/raindroppublic/website_data/Raindrop-modern-technology.png"
                alt="Total Solution Icon"
              />
            </div>
            <div className="back">
              <p>
                Raindrop's AI-Powered modular platform delivers business process automation across the entire
                Source-to-Pay user experience, reducing costs, and increasing efficiency.
              </p>
            </div>
          </div>
        </div>
        <div className="card middle">
          <div className="card-content">
            <div className="front">
              <h2>Ease of Use</h2>
              <img
                className="icon"
                src="https://storage.googleapis.com/raindroppublic/website_data/Raindrop-Ease-of-use.png"
                alt="Middle Icon"
              />
            </div>
            <div className="back">
              <p>
                The most modern and intuitive UI/UX quickly increases Stakeholder adoption because it is easy to use,
                shifting addressable spend to managed spend, delivering savings and transparency to increase your
                margins.
              </p>
            </div>
          </div>
        </div>
        <div className="card top-right">
          <div className="card-content">
            <div className="front">
              <h2>Speed to Value Attainment</h2>
              <img
                className="icon"
                src="https://storage.googleapis.com/raindroppublic/website_data/Raindrop-Speed-to-Value.png"
                alt="Top Right Icon"
              />
            </div>
            <div className="back">
              <p>
                Buy it, deploy it, and use it -- rapidly and happily. The days of needlessly long and expensive
                deployments are over.
              </p>
            </div>
          </div>
        </div>
        <div className="card bottom-right">
          <div className="card-content">
            <div className="front">
              <h2>Exceptional Service</h2>
              <img
                className="icon"
                src="https://storage.googleapis.com/raindroppublic/website_data/Raindrop-Exceptional-Service.png"
                alt="Bottom Right Icon"
              />
            </div>
            <div className="back">
              <p>Raindrop proudly overserves our customers, always there for you, and delivering to meet your needs.</p>
            </div>
          </div>
        </div>
      </main>
    </AppHomeAboutCardsWrapper>
  )
}
