import React from 'react'
import { AppContractsBanner } from '../components/AppContractsBanner'
// import { AppSaasAgreement } from '../components/AppSaasAgreement'
// import { AppSLAAgreement } from '../components/AppSLAAgreement'
// import { AppMDNAAgreement } from '../components/AppMDNAAgreement'
// import { AppServiceAgreement } from '../components/AppServiceAgreement'
import { AppLegalDataProcessingAgreement } from '../components/AppLegalDataProcessingAgreement'
import { AppLegalCustomer } from '../components/AppLegalCustomer'
import { AppLegalAcceptableUsePolicy } from '../components/AppLegalAcceptableUsePolicy'
import { RDHelmet } from '../components/RDHelmet'
import { Layout } from '../components/AppLayout'
import styled from 'styled-components'

const LegalWrapper = styled.div`
  font-family: 'GothamHTF', 'Helvetica Neue', sans-serif;
  body: {
    overflow-x: hidden;
    overflow-y: scroll;
  }
`

const legal = () => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '408-352-5162', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' 226 Airport Parkway Suite 250',
      addressRegion: ' San Jose, California 95110',
      postalCode: '95110',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }
  return (
    <LegalWrapper>
      <Layout>
        <RDHelmet
          subtitle={'legal'}
          contentDescription="Raindrop's legal page provides transparent contracts and policies globally."
          contentKeywords="content='Terms of Service, Privacy Policy, Legal agreements, User rights, Data compliance, Liability clauses, Intellectual property, Dispute resolution, Contract transparency, Raindrop legal policies'"
          contentOG="AI Powered Source-to-Pay SaaS Platform | Try Raindrop Free"
          contentOgDescription="Raindrop's legal page provides transparent contracts and policies globally."
          contentOgUrl="https://raindrop.com/legal"
          schemaMarkup={schemaMarkup}
        />

        <AppContractsBanner />
        {/* This time we are not using some Agreement but in future we going to using  */}
        {/* <AppSaasAgreement /> */}
        {/* <AppSLAAgreement /> */}
        {/* <AppMDNAAgreement /> */}
        {/* <AppServiceAgreement /> */}
        <AppLegalDataProcessingAgreement />
        <AppLegalCustomer />
        <AppLegalAcceptableUsePolicy />
      </Layout>
    </LegalWrapper>
  )
}

export default legal
