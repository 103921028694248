import React from 'react'
import GoogleMapReact from 'google-map-react'
import { GoogleMapMarker, RAINDROP_GOOGLE_MAP_API_KEY } from '../types'
import { RAINDROP_LAT_LNG } from '../services/GeocodeService'
import styled from 'styled-components'
import { ReUseHTwotag } from './ReUseHTwoTag'
import { ReUsePtag } from './ReUsePtag'
const AppMapCantactWapper = styled.div`
  margin-bottom: 60px;
  .MapSectionMain {
    display: flex;
    padding: 5rem 5rem 5rem 5rem;
    padding-bottom: 5rem;
    width: 90vw;
    margin-bottom: 60px;
    margin: auto;
    height: 70vh;
    border-radius: 42px;
    background-color: #102c64;
    @media (max-width: 1390px) {
      padding-top: 2rem;
      height: auto;
    }
    @media (max-width: 1080px) {
      flex-wrap: wrap;
    }
    @media (max-width: 650px) {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 3rem;
      margin-top: 560px;
    }
  }
  .googlemap {
    display: flex;
    justify-content: center;
    height: 50vh;
    width: 100%;
    border-radius: 32px;
    overflow: hidden; /* Add this */
  }

  .AdressSection {
    padding-left: 2rem;
    padding-top: 3rem;
    border-left: 2px solid #34a1d5;
    background-color: #f2f2f2;
    width: 750px;
    height: 50vh;
    border-radius: 32px;
    @media (max-width: 1080px) {
      order: 0;
      border-bottom: 2px solid #34a1d5;
      border-left: none;
      width: 950px;
      padding-top: 2rem;
      padding-left: unset;
    }
  }
  .MainAddresspara {
    max-width: 420px;
    padding-top: 0;
    margin: 0;
    font-size: 1rem;
    @media (max-width: 1080px) {
      text-align: center;
      /* padding-top: 2rem; */
      margin: auto;
    }
    @media (max-width: 650px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .phoneSection {
    padding-top: 2rem;
    font-size: 1rem;
    @media (max-width: 1080px) {
      text-align: center;
      padding: 0;
    }
  }
  .HeadingAddress {
    @media (max-width: 1080px) {
      text-align: center;
    }
  }
  .EmailSection {
    font-size: 1rem;
    @media (max-width: 1080px) {
      text-align: center;
    }
  }
`

export const AppMapCantact = () => {
  return (
    <AppMapCantactWapper>
      <div className="MapSectionMain">
        <div id="google-map-section" className="googlemap">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: RAINDROP_GOOGLE_MAP_API_KEY,
              libraries: ['places', 'geometry'],
            }}
            defaultCenter={RAINDROP_LAT_LNG}
            defaultZoom={12}
            hoverDistance={30}
          >
            <GoogleMapMarker lat={RAINDROP_LAT_LNG.lat} lng={RAINDROP_LAT_LNG.lng} />
          </GoogleMapReact>
        </div>
        <div className="AdressSection">
          <ReUseHTwotag Heading="Raindrop Headquarters" HeadingStyle="HeadingAddress" />
          <ReUsePtag para="226 Airport Parkway" paraStyle="MainAddresspara" />
          <ReUsePtag para="Suite 250" paraStyle="MainAddresspara" />
          <ReUsePtag para="San Jose, California 95110" paraStyle="MainAddresspara" />
          <ReUsePtag para="United States" paraStyle="MainAddresspara" />
          <ReUsePtag para="408-352-5162" paraStyle="phoneSection" />
          <ReUsePtag para="info@raindrop.com" paraStyle="EmailSection" />
        </div>
      </div>
    </AppMapCantactWapper>
  )
}
