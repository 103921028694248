import React from 'react'
import styled from 'styled-components'
import { AppVideoBanner } from './AppVideoBanner'
import SourcingEventsBannerData from '../Data/SourcingEventsBannerData.json'

const AppSourcingEventsBannerWapper = styled.div`
  .containSection {
    display: flex;
    max-width: 1920px;
    margin: auto;
    padding: 130px 30px 90px 30px;
    justify-content: space-between;
    overflow: hidden;
    @media (max-width: 1391px) {
      padding: 130px 20px 50px 20px;
    }
    @media (max-width: 1090px) {
      padding: 80px 15px;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .BannerSectionOne {
    margin-top: 2rem;
    margin-bottom: auto;
    @media (max-width: 1390px) {
      margin-top: auto;
    }
  }
  .BannerHeadingSize {
    max-width: 750px;
    line-height: 1.30435em;
    padding-bottom: 3%;
    font-size: 2.5rem;
    margin-top: auto;
  }
  .BannerPara {
    max-width: 950px;
    padding: 10px 20px 0px 0px;
  }
  .SourcingEventsSectionvideo {
    width: 65%;
    height: auto;
    @media (max-width: 1650px) {
      margin-top: 3%;
    }
    @media (max-width: 1390px) {
      width: 100%;
      margin-top: unset;
    }
  }
  .SourcingEventBannerContent {
    margin-top: 3%;
    @media (max-width: 1650px) {
      margin-top: unset;
    }
  }
  .BannerImage {
    width: 100%;
  }
  .whyRaindropListView {
    display: none;
  }
  .SourcingEventsSectionButton {
    /* display: flex;
    justify-content: center;
    margin: 40px 0px 20px 0px; */
    /* Hides the button */
    display: none;
  }
`

export const AppSourcingEventsBanner = () => {
  return (
    <AppSourcingEventsBannerWapper>
      {SourcingEventsBannerData.map((data, index) => {
        return (
          <div key={index}>
            <AppVideoBanner
              containSection="containSection"
              EventBannerHeading={data.heading}
              paraOne={data.paraOne}
              paraTwo={data.paraTwo}
              ImagePath={data.ImagePath}
              altName={data.AltName}
              ButtonContent={data.ButtonContent}
              InternalPageShiftUrl={data.ShiftInternalPath}
              ButtonStyle="SourcingEventsSectionButton"
              BannerSectionOne="BannerSectionOne"
              BannerSectionTwo="BannerSectionTwo item-2"
              EventBannerHeadingSize="BannerHeadingSize"
              EventBannerPara="BannerPara"
              EventBannerImage="BannerImage"
              UlStyle="whyRaindropListView"
              EventBannerSectionTwo="SourcingEventsSectionvideo"
              EventBannerSectionOne="SourcingEventBannerContent"
            />
          </div>
        )
      })}
    </AppSourcingEventsBannerWapper>
  )
}
