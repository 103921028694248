import React from 'react'
import { Layout } from '../components/AppLayout'
import styled from 'styled-components'
// import { Professional } from '../components/Professional'
// import { About } from '../components/About'
// import { AppHomeByCompanyCard } from '../components/AppHomeByCompanyCard'
import '../styles/global.css'
import { RDHelmet } from '../components/RDHelmet'
import { AppHomeBanner } from '../components/AppHomeBanner'
// import { AppHomeByRoleCard } from '../components/AppHomeByRoleCard'
import { AppHomeAwardSection } from '../components/AppHomeAwardSection'
import { AppHomeCustomersReview } from '../components/AppHomeCustomersReview'
import { AppHomeByFunctionCards } from '../components/AppHomeByFunctionCards'
import { AppHomeAboutCards } from '../components/AppHomeAboutCards'
import { AppHomeNewsSection } from '../components/AppHomeNewsSection'
import { AppCustomersReviewSection } from '../components/AppCustomersReviewSection'
import { AppHomeAboutUs } from '../components/AppHomeAboutUs'
import { AppHomeBookDemo } from '../components/AppHomeBookDemo'

const IndexWrapper = styled.div`
  body {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-y: scroll;
    overflow-x: hidden;
  }
`

export default () => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '408-352-5162', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' 226 Airport Parkway Suite 250',
      addressRegion: ' San Jose, California 95110',
      postalCode: '95110',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }
  return (
    <Layout>
      <IndexWrapper>
        <RDHelmet
          contentDescription="Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow."
          contentKeywords="content='Gen AI, Digital Signature, DocuSign, AdobeSign, eSign, RainSign, Catalogs, Intake Orchestration, Workflows, Scorecards, Collaboration, Business spend management software, Source-to-Pay, Full-Suite, Invoice Automation, Payables, RFP, Auction, Reverse Auction, CLM, Contract, Contract Lifecycle, Contract management, Sourcing, Analytics, Intelligence, PR2PO, ERP, SaaS spend management software, Enterprise spend management software, Spend management software market, Spend management software companies, Business spend management platform, Artificial intelligence software, AI-Powered spend management solution'"
          contentOG="AI Powered Source-to-Pay SaaS Platform | Try Raindrop Free"
          contentOgDescription="Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow."
          contentOgUrl="https://raindrop.com/"
          schemaMarkup={schemaMarkup}
        />
        {/* <Banner /> */}
        <AppHomeBanner />
        <AppHomeAwardSection />
        <AppHomeByFunctionCards />
        <AppHomeCustomersReview />
        <AppHomeAboutCards />
        <AppCustomersReviewSection />
        <AppHomeNewsSection />
        <AppHomeAboutUs />
        <AppHomeBookDemo />
        {/* <AppHomeByCompanyCard /> */}
        {/* <AppHomeByRoleCard /> */}
        {/* <About /> */}
        {/* <Professional /> */}
      </IndexWrapper>
    </Layout>
  )
}
